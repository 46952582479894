// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mihabodytec-js": () => import("./../src/pages/Mihabodytec.js" /* webpackChunkName: "component---src-pages-mihabodytec-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-plaza-js": () => import("./../src/pages/Plaza.js" /* webpackChunkName: "component---src-pages-plaza-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-zira-js": () => import("./../src/pages/Zira.js" /* webpackChunkName: "component---src-pages-zira-js" */)
}

